import React from "react"
import Helmet from "react-helmet"
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, SectionPageTitle, SectionDescription, BreadCrumb } from "../components/section"
import generateHTML from '../utils/generateHTML';
import ProductCardList from "../components/TabProductCardList"
import Content, { HTMLContent } from '../components/Content';
import QuotePopup from '../components/QuotePopup'
import OfferSection from '../components/Offer'

class SpecialsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    }
    this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('popup-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('popup-open')
    }
  }
  componentDidMount() {
  }
  render() {
    const { isVisibleQuote } = this.state
    const { data, location } = this.props
    const pageData = data.contentfulProductListPage
    const products = data.allContentfulProduct
    let productsData = []
    productsData=products.edges
    const PostContent = HTMLContent || Content;
    return(
      <Layout location={location} noSaleSticky>
        <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
        <SEO title="Title" description="Description"
        />
        <OfferSection showQuote = {this.showQuote} />
        {/* <Section pt="150px" pb="35px" bg="#F6F6F6" bdrBottom="#ddd">
          <div className="container">            
            <SectionPageTitle mt="30px" mb="20px" textAlign="center">{pageData.heroTitle}</SectionPageTitle>
            <SectionDescription><PostContent content={generateHTML(pageData.heroDescription.childMarkdownRemark.html)} /></SectionDescription>
            </div>
            </Section> */}
        <Section pt="70px" xpt="40px" mpt="30px" pb="40px" xpb="10px" mpb="0">  
          <div className="container">
            <SectionPageTitle mb="20px" textAlign="center">Check Out Our Most Popular Buildings</SectionPageTitle>
            <ProductCardList data={productsData} limit="all" showQuote = {this.showQuote} />
          </div>
        </Section>
        <QuotePopup
            isVisible={isVisibleQuote}
            onClose={this.onClosePopup}
            location={location}
        />
      </Layout>
    )
  }
}


export default SpecialsPage

export const pageQuery = graphql`
  query SpecialsPageQuery {
    contentfulProductListPage {
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          productName
          url
          popularLabel
          image {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          depositPrice
          priceDescription
          featuresImage {
            title
            file {
              url
            }
          }
          productCategory {
            link
          }
          productRoof {
            roofName
          }
        }
      }
    }
  }
`